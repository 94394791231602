<template>
  <v-container class="config-container pa-0">
    <v-container class=" pa-0">
      <ResultadoPorMesEmpresa :query="valueQuery" />
    </v-container>
    <v-container class="container-card">
      <v-card class="d-flex justify-space-around">
        <BaseInformationDashboard
          icon="mdi-chart-line"
          color="deep-orange darken-2"
          :value="campanhas"
          :hiddenValue="getHiddenValue"
          label="Nº CAMPANHAS"
        />
        <v-divider :vertical="true" class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-chart-line"
          color="deep-orange darken-2"
          :value="liquidadas"
          :hiddenValue="getHiddenValue"
          label="Nº LIQUIDADAS"
        />
        <v-divider :vertical="true" class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="realizado"
          :hiddenValue="getHiddenValue"
          label="REALIZADO LIQUIDADAS"
        />
        <v-divider :vertical="true" class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="bonus"
          :hiddenValue="getHiddenValue"
          label="BÔNUS"
        />
      </v-card>
      <v-row class="pa-0 mt-4">
        <v-col cols="12" md="6" lg="6">
          <ChartColumn
            :title="'Realizado'"
            :accumulator="'realizado'"
            :filters="filters"
          />
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <ChartColumn
            :title="'Bônus pago'"
            :accumulator="'resultado'"
            :filters="filters"
          />
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <ResultadoPorEmpresa
            :hiddenValue="getHiddenValue"
            :currentDate="filters"
            :apuracao="apuracaoData"
          />
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <DespesaCampanha :filters="filters" />
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <RealizadoEmpresa :filters="filters" />
        </v-col>
      </v-row>
    </v-container>

    <FilterDashboard @selectedFilters="syncFilters($event)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInformationDashboard from "@/components/shared/BaseInformationDashboard";
import FilterDashboard from "./FilterDashboard.vue";
import campanha from "@/services/http/campanhaService";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "Dashboard",
  mixins: [currencyMixin],
  components: {
    ResultadoPorMesEmpresa: () =>
      import(
        "@/components/campanha/dashboard/empresa/charts/ResultadoPorMesEmpresa"
      ),
    BaseInformationDashboard,
    FilterDashboard,
    ResultadoPorEmpresa: () =>
      import(
        "@/components/campanha/dashboard/empresa/charts/ResultadoPorEmpresa"
      ),
    DespesaCampanha: () =>
      import("@/components/campanha/dashboard/empresa/charts/DespesaCampanha"),
    ChartColumn: () =>
      import("@/components/campanha/dashboard/empresa/charts/ChartColumn"),
    RealizadoEmpresa: () =>
      import("@/components/campanha/dashboard/empresa/charts/RealizadoEmpresa")
  },
  data() {
    const baseQuery = {
      anoAtual: new Date().getFullYear(),
      campanhaFinalizada: 22,
      apuracaoAprovada: 1
    };
    return {
      query: {
        id_status_camp: baseQuery.campanhaFinalizada,
        ano_ref: baseQuery.anoAtual,
        id_status: baseQuery.apuracaoAprovada,
        per_page: -1
      },
      realizadoEmpresa: [],
      apuracaoData: [],
      filters: {},
      campanhas: "",
      liquidadas: "",
      realizado: "",
      bonus: ""
    };
  },

  computed: {
    ...mapGetters({
      getHiddenValue: "sistema/getHiddenValue"
    }),

    valueQuery() {
      return this.filters;
    }
  },

  methods: {
    async fetchResultadoRealizadoEmpresa() {
      let acumulatorRealizado = 0;
      let acumulatorBonus = 0;
      let { data } = await campanha()
        .resultadoRealizadoEmpresa()
        .show({
          per_page: -1,
          ...this.filters
        });

      this.realizadoEmpresa.push(data.data);
      data.data.map(item => {
        acumulatorRealizado += parseFloat(item.realizado) || 0;
        acumulatorBonus += parseFloat(item.resultado) || 0;
      });
      this.realizado = this.formatValue(acumulatorRealizado).toString();
      this.bonus = this.formatValue(acumulatorBonus).toString();
    },

    async fetchResultadoRealizadoCampanha() {
      let { data } = await campanha()
        .resultadoRealizadoCampanha()
        .show({
          per_page: -1,
          ...this.filters
        });
      this.liquidadas = `${data.data.length}`;
    },

    async fetchApuracao() {
      let { data } = await campanha()
        .apuracao()
        .show({ ...this.query, ...this.filters });
      this.apuracaoData = data.data;
      this.campanhas = `${data.total}`;
    },

    formatValue(value) {
      return value ? currencyMixin.filters.BrazilianCurrency(value) : 0;
    },

    async syncFilters(filter) {
      this.filters = Object.assign({}, filter);
      await this.fetchApuracao();
      await this.fetchResultadoRealizadoEmpresa();
      await this.fetchResultadoRealizadoCampanha();
    }
  },
  async mounted() {
    const date = new Date().toISOString().substr(0, 7);
    const [year, month] = date.split("-");
    const filter = {
      ano_ref: parseInt(year),
      mes_ref: parseInt(month)
    };
    this.syncFilters(filter);
  }
};
</script>

<style scoped>
.container-chart {
  margin-top: -90px;
}

.container-card {
  margin-top: -60px;
  padding: 12px 30px;
}

.config-container {
  background-color: #f5f5f5;
}

.divider-card {
  margin: 30px 0;
  border: 1px solid #e4e4e4;
}
</style>
